import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OTP from "./components/pages/OTP";
import Layout from "./components/layout/Layout";
import Dashboard from "./components/pages/Dashboard";
import Users from "./components/pages/Users";
import Adduser from "./components/pages/Adduser";
import Edituser from "./components/pages/Edituser";
import ProfileSettings from "./components/pages/ProfileSettings";
import Viewuser from "./components/pages/Viewuser";
import Notifications from "./components/pages/Notifications";
import Allpages from "./components/pages/Allpages";
import Payments from "./components/pages/Payments";
import Viewpayment from "./components/pages/Viewpayment";
import Airports from "./components/pages/Airports";
import Addairport from "./components/pages/Addairport";
import Editairport from "./components/pages/Editairport";
import Airplane from "./components/pages/Airplane";
import Addairplane from "./components/pages/Addairplane";
import Editairplane from "./components/pages/Editairplane";
import Positioningleg from "./components/pages/Positioningleg";
import Flights from "./components/pages/Flights";
import Addflight from "./components/pages/Addflight";
import Editflight from "./components/pages/Editflight";
import Booking from "./components/pages/Booking";
import Viewbooking from "./components/pages/Viewbooking";
import GrossProfit from "./components/pages/GrossProfit";
import AddTaxes from "./components/pages/AddTaxes";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./components/pages/Settings";
import PageNotFound from "./components/pages/PageNotFound";
import EmptyLeg from "./components/pages/EmptyLeg";
import AddEmptyLeg from "./components/pages/AddEmptyLeg";
import EditEmptyLeg from "./components/pages/EditEmptyLeg";
import ViewEmptyLeg from "./components/pages/ViewEmptyLeg";
import Checkout from "./components/pages//Checkout";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const isAuthenticated = () => {
    // Check if the access token exists in local storage
    return localStorage.getItem("ACCESS_TOKEN") !== null;
  };

  const PrivateRoute = ({ element, title }) => {
    return isAuthenticated() ? (
      <Layout title={title}>{element}</Layout>
    ) : (
      <Navigate to="/" replace />
    );
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <>
          <Router>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/OTP" element={<OTP />} />
              <Route path="/ResetPassword" element={<ResetPassword />} />
              <Route
                path="/Dashboard"
                element={
                  <PrivateRoute element={<Dashboard />} title="Dashboard" />
                }
              />
              <Route
                path="/Users"
                element={<PrivateRoute element={<Users />} title="Users" />}
              />
              <Route
                path="/Adduser"
                element={<PrivateRoute element={<Adduser />} title="Adduser" />}
              />
              <Route
                path="/Edituser/:id"
                element={
                  <PrivateRoute element={<Edituser />} title="Edit User" />
                }
              />
              <Route
                path="/Viewuser/:id"
                element={
                  <PrivateRoute element={<Viewuser />} title="User Details" />
                }
              />
              <Route
                path="/Notifications"
                element={
                  <PrivateRoute
                    element={<Notifications />}
                    title="Notifications"
                  />
                }
              />
              <Route
                path="/Allpages"
                element={
                  <PrivateRoute element={<Allpages />} title="All Pages" />
                }
              />
              <Route
                path="/Payments"
                element={
                  <PrivateRoute element={<Payments />} title="Payments" />
                }
              />
              <Route
                path="/Viewpayment/:id"
                element={
                  <PrivateRoute
                    element={<Viewpayment />}
                    title="Payment Details"
                  />
                }
              />
              <Route
                path="/Airports"
                element={
                  <PrivateRoute element={<Airports />} title="Airports" />
                }
              />
              <Route
                path="/Addairport"
                element={
                  <PrivateRoute element={<Addairport />} title="Addairport" />
                }
              />
              <Route
                path="/Editairport/:id"
                element={
                  <PrivateRoute
                    element={<Editairport />}
                    title="Edit Airport"
                  />
                }
              />
              <Route
                path="/Airplane"
                element={
                  <PrivateRoute element={<Airplane />} title="Airplanes" />
                }
              />
              <Route
                path="/Addairplane"
                element={
                  <PrivateRoute
                    element={<Addairplane />}
                    title="Add Airplane"
                  />
                }
              />
              <Route
                path="/Editairplane/:id"
                element={
                  <PrivateRoute
                    element={<Editairplane />}
                    title="Edit Airplane"
                  />
                }
              />
              <Route
                path="/Flights"
                element={<PrivateRoute element={<Flights />} title="Flights" />}
              />
              <Route
                path="/Positioningleg"
                element={
                  <PrivateRoute
                    element={<Positioningleg />}
                    title="Positioningleg"
                  />
                }
              />
              <Route
                path="/Addflight"
                element={
                  <PrivateRoute element={<Addflight />} title="Add Flight" />
                }
              />
              <Route
                path="/Editflight"
                element={
                  <PrivateRoute element={<Editflight />} title="Edit Flight" />
                }
              />
              <Route
                path="/Booking"
                element={<PrivateRoute element={<Booking />} title="Booking" />}
              />
              <Route
                path="/Checkout"
                element={<PrivateRoute element={<Checkout />} title="Checkout" />}
              />
              <Route
                path="/Viewbooking/:id"
                element={
                  <PrivateRoute
                    element={<Viewbooking />}
                    title="Booking Details"
                  />
                }
              />
              <Route
                path="/GrossProfit"
                element={
                  <PrivateRoute
                    element={<GrossProfit />}
                    title="Gross Profit"
                  />
                }
              />
              <Route
                path="/AddTaxes"
                element={
                  <PrivateRoute element={<AddTaxes />} title="Add Taxes" />
                }
              />
              <Route
                path="/AddTaxes"
                element={
                  <PrivateRoute element={<AddTaxes />} title="Add Taxes" />
                }
              />
              <Route
                path="/ProfileSettings"
                element={
                  <PrivateRoute
                    element={<ProfileSettings />}
                    title="Profile Settings"
                  />
                }
              />
              <Route
                path="/Settings"
                element={
                  <PrivateRoute element={<Settings />} title="Settings" />
                }
              />
              <Route
                path="/EmptyLegs"
                element={
                  <PrivateRoute element={<EmptyLeg />} title="Empty Leg" />
                }
              />
              <Route
                path="/AddEmptyLeg"
                element={
                  <PrivateRoute
                    element={<AddEmptyLeg />}
                    title="Add Empty Leg"
                  />
                }
              />
              <Route
                path="/EditEmptyLeg/:id"
                element={
                  <PrivateRoute
                    element={<EditEmptyLeg />}
                    title="Edit Empty Leg"
                  />
                }
              />
              <Route
                path="/ViewEmptyLeg/:id"
                element={
                  <PrivateRoute
                    element={<ViewEmptyLeg />}
                    title="Empty Leg Details"
                  />
                }
              />
              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </Router>
          {/* <ToastContainer /> */}
        </>
      )}
    </>
  );
}

export default App;
