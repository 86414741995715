import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import moment from "moment";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getApi, deleteApi } from "./axiosInstance/axiosConfig";
import { Sentry } from "react-activity";
export default function Booking() {
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const columns = [
    {
      name: "Passanger Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => row.fullName,
    },
    {
      name: "Aircraft",
      selector: (row) => row.modelNumber,
      sortable: true,
      cell: (row) => row.modelNumber,
    },
    {
      name: "Total Passenger",
      selector: (row) => row.capacity,
      sortable: true,
      cell: (row) => row.capacity,
    },
    {
      name: "Departure Time",
      selector: (row) => moment(row.outboundDate).format("YYYY-MM-DD HH:mm"),
      sortable: true,
      cell: (row) => moment(row.outboundDate).format("YYYY-MM-DD HH:mm"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/Viewbooking/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btndelete" onClick={handleShow}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  useEffect(() => {
    getApi("adminBookingList1", page, perPage, searchText)
      .then((res) => {
        console.log("rsp", res.data.list);
        setList(res.data.list);

        setTotalRows(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [page, perPage, searchText, deleted]);
  const handlePageChange = (page) => {
    console.log("Dsdsdsdsd", page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("Dsdsdsdsd1", newPerPage, page);
    setPage(page);
    setPerPage(newPerPage);
  };
  // const [data] = useState([
  //   {
  //     id: 1,
  //     name: 'John Smith',
  //     Flightname: 'Global 6000',
  //     Passenger: '14',
  //     DepartureTime: '09:00AM',
  //   },
  //   {
  //     id: 2,
  //     name: 'John Smith',
  //     Flightname: 'Global 6000',
  //     Passenger: '14',
  //     DepartureTime: '09:00AM',
  //   },
  //   {
  //     id: 3,
  //     name: 'John Smith',
  //     Flightname: 'Global 6000',
  //     Passenger: '14',
  //     DepartureTime: '09:00AM',
  //   },
  //   {
  //     id: 4,
  //     name: 'John Smith',
  //     Flightname: 'Global 6000',
  //     Passenger: '14',
  //     DepartureTime: '09:00AM',
  //   },
  //   {
  //     id: 5,
  //     name: 'John Smith',
  //     Flightname: 'Global 6000',
  //     Passenger: '14',
  //     DepartureTime: '09:00AM',
  //   },
  // ]);

  // Filter data based on search text
  // const filteredData = data.filter(
  //   item =>
  //     JSON.stringify(item)
  //       .toLowerCase()
  //       .indexOf(searchText.toLowerCase()) !== -1
  // );
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header className="py-4">
                  <h5>Booking</h5>
                </Card.Header>
                <Card.Body>
                  <div className="text-end mb-3">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="searchfield"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={list}
                    progressPending={loading}
                    pagination
                    responsive
                    paginationServer
                    paginationPerPage={perPage}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationRowsPerPageOptions={[5, 10, 20]}
                    onChangePage={handlePageChange}
                    className="custom-table"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal_Delete"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Body>
              <div className="modaldelete_div">
                <Icon className="delete_icon" icon="gg:close-o"></Icon>
                <h3>Are You Sure ?</h3>
                <p>You will not be able to recover the deleted record!</p>
              </div>
              <Button
                onClick={handleClose}
                className="btn btn-secondary px-4 me-3"
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary px-4 min_width110"
                onClick={handleClose}
              >
                Ok
              </Button>
            </Modal.Body>
          </Modal>
        </React.Fragment>
      )}
      ;
    </>
  );
}
