import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import avatar1 from "../assets/img/airplaneimg1.jpg";
import { getUsers } from "./axiosInstance/axiosConfig";
import { S3_BASE_URL } from "../helper/config";
import { getApi,deleteApi,postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Sentry } from "react-activity";
export default function Airplane() {
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedId(null);
  };
  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };
  const handleDelete = (id) => {
    console.log("id",id);
    handleShow();
    postApi(`deleteaircraft?aircraftId=${selectedId}`)
    handleClose();
    toast.success("Aircraft deleted Successful", {
      autoClose: 1000 
    });
    setDeleted(!deleted);
    // handleAnotherEvent();
  };
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const columns = [
    {
      name: "Image",
      selector: (row) => row.imgurl,
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            src={row.aircraftPic[0] ? `${S3_BASE_URL}${row.frontImg}` : avatar1}
            alt="aircraft img"
          />
          {/* {row.aircraftPic[0]} */}
        </>
      ),
    },
    {
      name: "Type",
      selector: (row) => row.airplaneType,
      sortable: true,
      cell: (row) => row.airplaneType,
    },
    {
      name: "Seats",
      selector: (row) => row.capacity,
      sortable: true,
      cell: (row) => row.capacity,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnedit" to={`/Editairplane/${row._id}`}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  useEffect(() => {
    getApi("aircraftList", page, perPage,searchText)
      .then((res) => {
        console.log(res.data.list);
        setUsers(res.data.list);

        setTotalRows(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [page,perPage, searchText,deleted]);
  const handlePageChange = (page) => {
    console.log("Dsdsdsdsd", page);
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    console.log("Dsdsdsdsd1",newPerPage, page);
    setPage(page);
    setPerPage(newPerPage);
  };
  return (
    <>
    {loading ? (
      <div className="loaderdiv">
        <Sentry color="#003CBF" size={40} speed={1} animating={true} />
      </div>
    ) : (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Airplanes</h5>
              <Button
                className="btn btn-primary px-4 min_width140"
                as={Link}
                to="/Addairplane"
              >
                Add
              </Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={users}
                pagination
                responsive
                progressPending={loading}
                paginationServer
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationRowsPerPageOptions={[5, 10, 20]}
                onChangePage={handlePageChange}
                className="custom-table"
              />
            </Card.Body>
          </Card>
          <ToastContainer />
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-secondary px-4 me-3">
            Cancel
          </Button>
          <Button
            className="btn btn-primary px-4 min_width110"
            onClick={handleDelete}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
    )};
  </>
  );
}
