import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function ForgotPassword() {
  const location = useLocation();
  const data = location.state;
  console.log("data-------",data);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const handleSubmit = async (e) => {
    console.log("working");
    e.preventDefault();
    try {
      console.log("rsp12345for");
      let obj = {
        OTP: otp,
        hash: data.hash,
        contact: data.contact,
      };
      const response = await postApi("verifyOTP", obj);
      if (response?.status) {
        const data = await response.data;
        let datatoken = response.data.userToken;
        console.log(data);
        toast.success("OTP verified.", {
          onClose: () => navigate("/ResetPassword",{ state: datatoken }),
          autoClose: 2000, // Display toast for 3 seconds
        });
      } else {
        toast.error(response);
      }
      // console.log("rspfor");
      // let datatoken = response.data.userToken;
      // console.log("token11111",datatoken);
      // // toast.success("Edit Successful");
      // toast.success("OTP verified.", {
      //   onClose: () => navigate("/ResetPassword",{ state: datatoken }),
      //   autoClose: 2000, // Display toast for 3 seconds
      // });
      // navigate("/OTP");
      console.log("working1");
      setError("");
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>OTP Verification</h2>
              <p>
                Please enter the 6 digit verification code sent on your
                registered email address.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Group>
              {/* <Link to="/ResetPassword">
                <Button
                  as="input"
                  type="submit"
                  value="Proceed"
                  className="btn btn-primary w-100 mt-3"
                />
              </Link> */}
              <Button
                className="btn btn-primary px-4 mt-3 min_width140"
                type="submit"
              >
                Proceed
              </Button>
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
}
