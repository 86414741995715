import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { getApi, postApi, getApiAirport } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { Sentry } from "react-activity";
const DepartureAirport = [
  {
    value: "DepartureAirport1",
    label: "Hartsfield–Jackson Atlanta International Airport",
  },
  {
    value: "DepartureAirport2",
    label: "Dallas Fort Worth International Airport",
  },
];
const ArrivalAirport = [
  {
    value: "ArrivalAirport1",
    label: "Hartsfield–Jackson Atlanta International Airport",
  },
  {
    value: "ArrivalAirport2",
    label: "Dallas Fort Worth International Airport",
  },
];
export default function EditEmptyLeg() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [leg, setLeg] = useState({
    _id: id,
    aircraftType: "",
    departureAirport: "",
    arrivalAirport: "",
    startDate: new Date(),
    endDate: new Date(),
    Price: "",
    Notes: "",
    status: "",
  });
  const [departureAirport, setDepartureAirport] = useState(
    leg?.departureAirport
      ? {
          label: leg.departureAirport.airportName,
          value: leg.departureAirport.code,
        }
      : null
  );
  const [arrivalAirport, setArrivalAirport] = useState(
    leg?.arrivalAirport
      ? {
          label: leg.arrivalAirport.airportName,
          value: leg.arrivalAirport.code,
        }
      : null
  );
  const [AircraftType, setAircraftType] = useState("select");
  const [Price, setPrice] = useState("");
  const [status, setStatus] = useState("select");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [submitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #DADADA",
      boxShadow: "none",
      minHeight: "52px",
      borderRadius: "8px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#003cbf", // Change background color of the selected value container
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
  };
  useEffect(() => {
    getApi("viewEmptyLeg?legId=" + id)
      .then((res) => {
        console.log("viewEmptyLeg-->", res.data);
        setLeg({
          ...res.data,
          departureAirport: {
            label: res.data.departureAirport.airportName,
            value: res.data.departureAirport.code,
          },
          arrivalAirport: {
            label: res.data.arrivalAirport.airportName,
            value: res.data.arrivalAirport.code,
          },
        });
        // setDepartureAirport({
        //   label: res.data.departureAirport.airportName,
        //   value: res.data.departureAirport.code,
        // });
        console.log(departureAirport);
        setArrivalAirport({
          label: res.data.arrivalAirport.airportName,
          value: res.data.arrivalAirport.code,
        });
        console.log("legId", leg);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [id]);
  useEffect(() => {
    const fetchDefaultOptions = async () => {
      try {
        const response = await getApiAirport("aircraftListAdmin", "");
        if (response?.status) {
          const options = response.data.map((aircraft) => ({
            value: aircraft?._id,
            label: aircraft?.modelNumber,
          }));
          setDefaultOptions(options);
        }
      } catch (err) {
        console.error(err);
        toast.error(
          "An error occurred while fetching default aircraft options"
        );
      }
    };

    fetchDefaultOptions();
  }, []);
  const loadOptions = async (inputValue, callback) => {
    try {
      const response = await getApiAirport("airportList", inputValue);
      if (response?.status) {
        const options = response.data?.map((airport) => ({
          value: airport.ident, // Unique identifier for the airport
          label: `${airport.name}`,
        }));
        console.log("airportswl", options);
        callback(options);
      } else {
        // setSubmitting(false);
      }
    } catch (err) {
      console.error("err1", err);
      toast.error("An error occurred while adding the aircraft");
    }
  };
  const loadAircraft = async (inputValue, callback) => {
    try {
      const response = await getApiAirport("aircraftListAdmin", inputValue);
      if (response?.status) {
        const options = response.data.map((airport) => ({
          value: airport._id, // Unique identifier for the airport
          label: `${airport.modelNumber}`,
        }));
        console.log("airport", options);
        callback(options);
      } else {
        // setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while adding the aircraft");
    }
  };
  const handleAircraftChange = (selectedOption) => {
    setAircraftType(selectedOption);
  };
  const handleDepartureAirport = (selectedOption) => {
    console.log("setDepartureAirport1", departureAirport);
    // setDepartureAirport(selectedOption);
    setLeg((prevLeg) => ({
      ...prevLeg,
      departureAirport: selectedOption,
    }));
    console.log("setDepartureAirport", departureAirport);
  };
  // Log the state whenever it changes
  // useEffect(() => {
  //   console.log("Updated departureAirport:", departureAirport);
  //   console.log("Updated arrivalAirport:", arrivalAirport);
  // }, [departureAirport, arrivalAirport]);

  const handleArrivalAirport = (selectedOption) => {
    // setArrivalAirport(selectedOption);
    setLeg((prevLeg) => ({
      ...prevLeg,
      arrivalAirport: selectedOption,
    }));
    console.log("arrivalAirport", arrivalAirport);
  };
  const handleDateChange = (date, name) => {
    setLeg((prevDates) => ({
      ...prevDates,
      [name]: date,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const legObj = leg;
    console.log("Form submitted with departureAirport:", departureAirport);
    try {
      const response = await postApi("editEmptyLeg", legObj);
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Edit Successful", {
          onClose: () => navigate(-1),
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    } finally {
      setSubmitting(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeg((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#003CBF" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <React.Fragment>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
              <h5>Edit Empty Leg</h5>
              <Link
                className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
                to="/EmptyLegs"
              >
                Back
              </Link>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>From</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleDepartureAirport}
                        className="multiselect"
                        styles={customStyles}
                        value={leg.departureAirport}
                        placeholder="Search for an airport..."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>To</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleArrivalAirport}
                        className="multiselect"
                        styles={customStyles}
                        value={leg.arrivalAirport}
                        placeholder="Search for an airport..."
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Departure Date & Time</Form.Label>
                      <DatePicker
                        name="startDate"
                        selected={
                          leg.startDate ? new Date(leg.startDate) : null
                        }
                        onChange={(date) => handleDateChange(date, "startDate")}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        value={
                          leg.startDate
                            ? moment(leg.startDate).format(
                                "YYYY-MM-DD , HH:mm A"
                              )
                            : leg.startDate
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Arrival Date & Time</Form.Label>
                      <DatePicker
                        name="endDate"
                        selected={leg.endDate ? new Date(leg.endDate) : null}
                        onChange={(date) => handleDateChange(date, "endDate")}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        value={
                          leg.endDate
                            ? moment(leg.endDate).format("YYYY-MM-DD , HH:mm A")
                            : leg.endDate
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Aircraft Type</Form.Label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={defaultOptions}
                        loadOptions={loadAircraft}
                        onChange={handleAircraftChange}
                        className="multiselect"
                        styles={customStyles}
                        value={{
                          label: leg.aircraftType.modelNumber,
                          value: leg.aircraftType.modelNumber,
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Price</Form.Label>
                      <Form.Control
                        name="Price"
                        type="text"
                        placeholder="Enter Price"
                        value={leg.Price}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        name="status"
                        aria-label="Select Status"
                        onChange={handleChange}
                      >
                        <option value="select">{leg?.status}</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  className="btn btn-primary px-4 mt-3 min_width140"
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <ToastContainer />
        </React.Fragment>
      )}
      ;
    </>
  );
}
