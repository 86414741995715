import React, { useState } from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../assets/img/default.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { postApiformdata } from "./axiosInstance/axiosConfig";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Adduser() {
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    profilePic: null,
    accountStatus: null,
    profileImagePreview: Avatar,
    password: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [status, setStatus] = useState("select");
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log("change", user);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imagePreviewUrl = URL.createObjectURL(file);
      setUser((prevData) => ({
        ...prevData,
        profilePic: file,
        profileImagePreview: imagePreviewUrl,
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append("fullName", user.fullName);
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("profilePic", user.profilePic);
    formData.append("accountStatus", user.accountStatus);
    formData.append("password", user.password);
    try {
      const response = await postApiformdata("adminAdduser", formData);
      console.log("response", response);
      // return;
      // const res = await axios.post(
      //   "http://54.152.167.122/v1/api/adminAdduser",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("User added Successful", {
          onClose: () => navigate("/Users"), // Navigate after toast is closed
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (err) {
      console.error(err);
      setMessage("Failed to update profile");
    } finally {
      // setSubmitting(false);
      // Enable the submit button after the form submission is complete
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add User</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Users"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 ">
              <div className="user_info text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={user.profileImagePreview}
                    className="rounded-circle img-fluid profilepic"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="uploadprofileimg"
                  className="d-none"
                  onChange={handleImageChange}
                />
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="Enter name"
                        value={user?.fullName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={user?.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3" style={{ position: "relative" , height:"100px"}}>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter password"
                        value={user?.password}
                        onChange={handleChange}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter Phone No."
                        value={user?.phoneNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        aria-label="Select Status"
                        name="accountStatus"
                        value={user?.accountStatus}
                        onChange={handleChange}
                      >
                        <option value="select">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                  disabled={submitting}
                >
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
