import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
export default function Login() {
  const location = useLocation();
  const data = location.state;
  console.log("data-------resetpassword",data);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const handleSubmit = async (e) => {
    console.log("working");
    e.preventDefault();
    try {
      console.log("rsp12345for");
      let obj = {
        newPassword: newPassword,
        confirmPassword: confirmPassword,
        token:data
      };
      const response = await postApi("resetPasswordAdmin", obj);
      console.log("rspfor");
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Password updated successfully.", {
          onClose: () => navigate("/"),
          autoClose: 2000, // Display toast for 3 seconds
        });
      } else {
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Reset Password</h2>
              <p>Please enter the details below to reset your password.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
              {/* <Link to="/"> */}
                <Button
                  as="input"
                  type="submit"
                  value="Reset Password"
                  className="btn btn-primary w-100"
                />
              {/* </Link> */}
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  );
}
