import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Avatar from "../assets/img/userimg1.jpg";
import { getApi, postApiformdata } from "./axiosInstance/axiosConfig";
import axios from "axios";
import { S3_BASE_URL } from "../helper/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatardefault from "../assets/img/default.jpg";
export default function Edituser() {
  const { id } = useParams();
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    profilePic: null,
    accountStatus: null,
    profileImagePreview: avatardefault,
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("John Smith");
  const [email, setEmail] = useState("johndoe@gmail.com");
  const [Phone, setPhone] = useState("+01 123 456 789");
  const [status, setStatus] = useState("1");
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log("change",user);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("files--------",file);
    if (file) {
      const imagePreviewUrl = URL.createObjectURL(file);
      setUser((prevData) => ({
        ...prevData,
        profilePic: file,
        profileImagePreview: imagePreviewUrl,
      }));
    }
  };
  useEffect(() => {
    getApi("admingetUser?userId=" + id)
      .then((res) => {
        console.log(res.data);
        setUser({
            ...res.data,
            profileImagePreview: res.data.profilePic
              ? `${S3_BASE_URL}${res.data.profilePic}`
              : avatardefault,
          });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [id]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append("fullName", user.fullName);
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("profileFile", user.profilePic);
    formData.append("userId", user._id);
    formData.append("accountStatus", user.accountStatus);
    try {
      const response = await postApiformdata("editAdminUserProfile", formData);
      console.log("edituser",response);
      // const res = await axios.post(
      //   "http://54.152.167.122/v1/api/editAdminUserProfile",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      // setMessage(res.data.message);
      // console.log("rspfor", res.data.data);
      // toast.success("User edit Successful", {
      //   autoClose: 1000 // Display toast for 3 seconds
      // });
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("User edit Successful", {
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        console.error("qresponse",response);
        setSubmitting(false);
        toast.error(response);
      }
    } catch (err) {
      // toast.error(err);
      console.error("q",err);
      setMessage("Failed to update profile");
    }finally{
      setSubmitting(false);
    }
  };
 
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit User</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Users"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 ">
              <div className="user_info text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={user.profileImagePreview}
                    className="rounded-circle img-fluid profilepic"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  type="file"
                  id="uploadprofileimg"
                  className="d-none"
                  onChange={handleImageChange}
                />
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="Enter name"
                        value={user?.fullName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={user?.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter phone no."
                        value={user?.phoneNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        aria-label="Select Status"
                        name="accountStatus"
                        value={user?.accountStatus}
                        onChange={handleChange}
                      >
                        {/* <option value="select">Select Status</option> */}
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button className="btn btn-primary px-4 mt-3 min_width140" type = "submit"
                disabled={submitting}>
                  Update
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
