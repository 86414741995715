import React from "react";
import { Dropdown, Breadcrumb, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Avatar from "../assets/img/userimg1.jpg";
import { S3_BASE_URL } from "../helper/config";
const Header = ({ title }) => {
  const navigate = useNavigate();
  const storedAvatar = JSON.parse(localStorage.getItem("rodeouserData"));
  //   const userData = JSON.parse(storedAvatar);
  const handleLogout = () => {
    // logout();
    localStorage.removeItem("ACCESS_TOKEN");
    navigate("/"); // Redirect to home or login page after logout
  };
  console.log("rodeouserData", storedAvatar.profilePic);
  return (
    <header className="header">
      <Row>
        <Col xs={6} className="align-self-center">
          <div className="mainbreadcrumb d-none d-md-block">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
              <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>
            {/* <h1>{title}</h1> */}
          </div>
        </Col>
        <Col xs={6} className="text-end">
          <div
            className="headerright"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Link
              underline="none"
              className="mx-3 linksheader"
              to="/Notifications"
            >
              <Icon icon="solar:bell-outline" />
            </Link>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="p-0"
                variant={null}
              >
                <img
                  alt=""
                  src={
                    storedAvatar
                      ? `${S3_BASE_URL}${storedAvatar.profilePic}`
                      : Avatar
                  }
                />
                {/* src={user.profilePic?`${S3_BASE_URL}${user.profilePic}`:Avatar} */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/ProfileSettings">
                  Profile Settings
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={handleLogout}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
