import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
export default function Flights() {
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const columns = [
    {
      name: 'Name',
      selector: row => row.Name,
      sortable: true,
      cell: (row) => row.Name
    },
    {
      name: 'Airplane',
      selector: row => row.Airplane,
      sortable: true,
      cell: (row) => row.Airplane
    },
    {
      name: 'From',
      selector: row => row.From,
      sortable: true,
      cell: (row) => row.From
    },
    {
      name: 'To',
      selector: row => row.To,
      sortable: true,
      cell: (row) => row.To
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btnedit" to='/Editflight'>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={handleShow}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data] = useState([
    { 
      id: 1, 
      Name: 'Global 6000', 
      Airplane: 'Ultra Long Range Jet', 
      From: 'Temale International Airport', 
      To: 'Kumasi International Airport', 
    },
    { 
      id: 2, 
      Name: 'Global 6000', 
      Airplane: 'Ultra Long Range Jet', 
      From: 'Temale International Airport', 
      To: 'Kumasi International Airport', 
    },
    { 
      id: 3, 
      Name: 'Global 6000', 
      Airplane: 'Ultra Long Range Jet', 
      From: 'Temale International Airport', 
      To: 'Kumasi International Airport', 
    },
    { 
      id: 4, 
      Name: 'Global 6000', 
      Airplane: 'Ultra Long Range Jet', 
      From: 'Temale International Airport', 
      To: 'Kumasi International Airport', 
    },
    { 
      id: 5, 
      Name: 'Global 6000', 
      Airplane: 'Ultra Long Range Jet', 
      From: 'Temale International Airport', 
      To: 'Kumasi International Airport', 
    },
  ]);

  // Filter data based on search text
  const filteredData = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) !== -1
  );
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Flights</h5>
              <Button className="btn btn-primary px-4 min_width140" as={Link} to='/Addflight'>Add</Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-secondary px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
