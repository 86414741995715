import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GrossProfit() {
  const [positioningSpeed, setPositioningSpeed] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const positioningSpeedobj = {
      positioningSpeed: parseFloat(positioningSpeed),
    };

    try {
      const response = await postApi(
        "insertPositioningSpeed",
        positioningSpeedobj
      );
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Edit Successful", {
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    getApi("getPositioningSpeed")
      .then((res) => {
        console.log(res.data);
        setPositioningSpeed(res.data.positioningSpeed);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, []);
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-4">
          <h5>Positioning Speed</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Positioning Speed</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Positioning Speed"
                    value={positioningSpeed}
                    onChange={(e) => setPositioningSpeed(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              type="submit"
              className="btn btn-primary px-4 mt-3 min_width140"
              disabled={submitting}
            >
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
