import axios from "axios";
import { BASE_API_URL, ACCESS_TOKEN } from "../../helper/config";
let resetToken = null;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json", // Set default content type if needed
  },
});
const axiosInstancetwo = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "multipart/form-data", // Set default content type if needed
  },
});

// Add an interceptor for setting the authorization token
axiosInstance.interceptors.request.use(
  (config) => {
    // console.log(
    //   "token01",
    //   ACCESS_TOKEN,
    //   JSON.parse(localStorage.getItem("ACCESS_TOKEN"))
    // );
    const accessToken = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
    config.headers.Authorization = `Bearer ${accessToken || resetToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstancetwo.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
    config.headers.Authorization = `Bearer ${accessToken || resetToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses and errors
axiosInstance.interceptors.response.use(
  (response) => {
    // console.log("Response1:", response);
    return response;
  },
  (error) => {
    if (error.response) {
      console.log("Error Response:11", error.response);
      // console.log("Error Status Code:2", error.response.status);
      // console.log("Error Data:3", error.response.data);
      if (error.response.status === 401) {
        localStorage.removeItem("ACCESS_TOKEN");
        window.location.href = "/";
      }
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response received:", error.request);
    } else {
      // Something went wrong in setting up the request
      console.error("Error Message:", error.message);
    }
    return Promise.reject(error);
  }
);
axiosInstancetwo.interceptors.response.use(
  (response) => {
    console.log("Response2:", response);
    return response;
  },
  (error) => {
    if (error.response) {
      console.log("Error Response:111", error.response);
      if (error.response.status === 401) {
        localStorage.removeItem("ACCESS_TOKEN");
        window.location.href = "/";
      }
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error Message:", error.message);
    }
    return Promise.reject(error);
  }
);

export const getApi = async (url, page, perPage, searchText) => {
  try {
    const response = await axiosInstance.get(url, {
      params: {
        page,
        perPage,
        searchText,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
export const getApiAirport = async (url, search) => {
  try {
    const response = await axiosInstance.get(url, {
      params: {
        search,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
export const getDashboardApi = async (
  url,
  page,
  perPage,
  searchText,
  userFilter,
  earnFilter
) => {
  try {
    const response = await axiosInstance.get(url, {
      params: {
        page,
        perPage,
        searchText,
        userFilter,
        earnFilter,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
export const postApi = async (url, body) => {
  resetToken = body?.token;
  try {
    const response = await axiosInstance.post(url, body);
    console.log("apirsp", response);
    return response.data;
  } catch (error) {
    // console.error("Error fetching data-----: ", error, error.response?.status);
    // console.error("Error fetching data: ", typeof error.response.status);
    console.log("dssdxsxsxsxsd");

    if (error.response?.status == 401) {
      console.log("dssdsd");
      window.location.href = "/";

      // Token has expired or is invalid; redirect to login
      // localStorage.removeItem("ACCESS_TOKEN");
      // Optionally, remove other user data as needed
      // history.push("/login"); // Assuming you have React Router set up
    } else {
      console.log("dssdsd1111",error.data);
      return error.response.data.errors
      ? error.response.data.errors[0].msg
      : error.response.data.message;
    }
    // return error.response.data.errors
    //   ? error.response.data.errors[0].msg
    //   : error.response.data.message;
  }
};
export const postApiformdata = async (url, body) => {
  resetToken = body?.token;
  try {
    console.log("body", body);
    const response = await axiosInstancetwo.post(url, body);
    console.log("apirsp", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:1 post ", error.response.data.message);
    return error.response.data.errors
      ? error.response.data.errors[0].msg
      : error.response.data.message;
  }
};
export const deleteApi = async (url, body) => {
  try {
    const response = await axiosInstance.delete(url, body);
    console.log("response--->", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
// export const getPosts = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

// export const viewPost = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

// export const getGames = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

// export const viewGame = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

// export const login = (url, data) => {
//   console.log(1);
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .post(url, data)
//       .then((response) => {
//         if (response.data.success) {
//           resolve(response.data);
//         } else {
//           reject(response.data);
//         }
//       })
//       .catch((error) => {
//         if (error.response && error.response.data && error.response.data.msg) {
//           reject(error.response.data.msg); // Reject with error message from backend
//         } else {
//           reject("An error occurred"); // Fallback error message
//         }
//       });
//   });
// };

// export const updateProfile = (url, formData) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .put(url, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then((response) => {
//         if (response.data.success) {
//           resolve(response.data);
//         } else {
//           reject(response.data);
//         }
//       })
//       .catch((error) => {
//         if (error.response && error.response.data && error.response.data.msg) {
//           reject(error.response.data.msg); // Reject with error message from backend
//         } else {
//           reject("An error occurred"); // Fallback error message
//         }
//       });
//   });
// };

// export const account = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };
// // Function to get user list with a promise-based approach
// export const getUsers = (url) => {
//   return new Promise((resolve, reject) => {
//     axiosInstance
//       .get(url)
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };