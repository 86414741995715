import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Editairport() {
  const [airport, setAirport] = useState({
    name: "",
    icao_code: "",
    iata_code: "",
    country: "",
    airportCity: "",
    handlingFees: "",
    fuelSurcharge: "",
    substituteBaseFor: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [name, setName] = useState("Temale International Airport");
  const [Code, setCode] = useState("TEL");
  const [Country, setCountry] = useState("Ghana");
  const [City, setCity] = useState("Tamale");
  const [HandlingFees, setHandlingFees] = useState("");
  const [FuelSurcharges, setFuelSurcharges] = useState("");
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAirport((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await postApi("editAirport", {
        ...airport,
        airportId: id,
      });
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Airport edit Successful", {
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  console.log("id", id);
  useEffect(() => {
    getApi("getAirport?airportId=" + id)
      .then((res) => {
        console.log(res.data);
        setAirport(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, [id]);
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit Airport</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Airports"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        value={airport.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>ICAO Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Code"
                        name="icao_code"
                        value={airport.icao_code}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>IATA Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="iata_code"
                        placeholder="Enter Code"
                        value={airport.iata_code}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        value={airport.country}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="airportCity"
                        placeholder="Enter City"
                        value={airport.airportCity}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Airport Fees</h4>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Handling Fees</Form.Label>
                      <Form.Control
                        type="text"
                        name="handlingFees"
                        placeholder="Enter Handling Fees"
                        value={airport.handlingFees}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Fuel Surcharges</Form.Label>
                      <Form.Control
                        type="text"
                        name="fuelSurcharge"
                        placeholder="Enter Fuel Surcharges"
                        value={airport.fuelSurcharge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="submit"
                >
                  Update
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
