import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { getApi, postApi, getApiAirport } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AsyncSelect from "react-select/async";
const DepartureAirport = [
  {
    value: "DepartureAirport1",
    label: "Hartsfield–Jackson Atlanta International Airport",
  },
  {
    value: "DepartureAirport2",
    label: "Dallas Fort Worth International Airport",
  },
];
const ArrivalAirport = [
  {
    value: "ArrivalAirport1",
    label: "Hartsfield–Jackson Atlanta International Airport",
  },
  {
    value: "ArrivalAirport2",
    label: "Dallas Fort Worth International Airport",
  },
];
export default function AddEmptyLeg() {
  const navigate = useNavigate();
  const [AircraftType, setAircraftType] = useState("");
  const [departureAirport, setDepartureAirport] = useState("");
  const [arrivalAirport, setArrivalAirport] = useState("");
  const [Price, setPrice] = useState("");
  const [status, setStatus] = useState("select");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [defaultOptions, setDefaultOptions] = useState([]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #DADADA",
      boxShadow: "none",
      minHeight: "52px",
      borderRadius: "8px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#003cbf", // Change background color of the selected value container
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
  };

  useEffect(() => {
    const fetchDefaultOptions = async () => {
      try {
        const response = await getApiAirport("aircraftListAdmin", "");
        if (response?.status) {
          const options = response.data.map((aircraft) => ({
            value: aircraft?._id,
            label: aircraft?.modelNumber,
          }));
          setDefaultOptions(options);
        }
      } catch (err) {
        console.error(err);
        toast.error(
          "An error occurred while fetching default aircraft options"
        );
      }
    };

    fetchDefaultOptions();
  }, []);

  const loadOptions = async (inputValue, callback) => {
    try {
      const response = await getApiAirport("airportList", inputValue);
      if (response?.status) {
        const options = response.data?.map((airport) => ({
          value: airport.ident, // Unique identifier for the airport
          label: `${airport.name}`,
        }));
        console.log("airportselect", options);
        callback(options);
      } else {
        // setSubmitting(false);
      }
    } catch (err) {
      console.error("err1", err);
      toast.error("An error occurred while adding the aircraft");
    }
  };
  const loadAircraft = async (inputValue, callback) => {
    try {
      const response = await getApiAirport("aircraftListAdmin", inputValue);
      if (response?.status) {
        const options = response.data.map((airport) => ({
          value: airport._id, // Unique identifier for the airport
          label: `${airport.modelNumber}`,
        }));
        console.log("airport", options);
        callback(options);
      } else {
        // setSubmitting(false);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while adding the aircraft");
    }
  };
  const handleAircraftChange = (selectedOption) => {
    setAircraftType(selectedOption);
  };
  const handleDepartureAirport = (selectedOption) => {
    setDepartureAirport(selectedOption);
  };
  const handleArrivalAirport = (selectedOption) => {
    setArrivalAirport(selectedOption);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const emptyLegObj = {
      departureAirport,
      arrivalAirport,
      startDate,
      endDate,
      Price: parseFloat(Price),
      aircraftType: AircraftType ? AircraftType.value : null,
    };

    try {
      const response = await postApi("addEmptyLeg", emptyLegObj);
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Empty leg added Successfully.", {
          onClose: () => navigate(-1),
          autoClose: 500,
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    } finally {
      setSubmitting(false);
    }
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedTimeArrival = (time) => {
    const currentDate = new Date(startDate);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add Empty Leg</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/EmptyLegs"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>From</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    onChange={handleDepartureAirport}
                    type="text"
                    // className="multiselect"
                    styles={customStyles}
                    placeholder="Search for an airport..."
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>To</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    onChange={handleArrivalAirport}
                    // className="multiselect"
                    styles={customStyles}
                    placeholder="Search for an airport..."
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Departure Date & Time</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate(date);
                    }}
                    showTimeSelect
                    dateFormat="Pp"
                    className="form-control"
                    minDate={new Date()} // Prevent selection of past dates
                    filterTime={filterPassedTime}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Arrival Date & Time</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    dateFormat="Pp"
                    className="form-control"
                    minDate={new Date(startDate)} // Prevent selection of past dates
                    filterTime={filterPassedTimeArrival}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Aircraft Type</Form.Label>
                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadAircraft}
                    onChange={handleAircraftChange}
                    defaultOptions={defaultOptions}
                    className="multiselect"
                    styles={customStyles}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Price"
                    value={Price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="Select Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="select">Select Status</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col> */}
            </Row>
            <Button
              type="submit"
              className="btn btn-primary px-4 mt-3 min_width140"
            >
              Save
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
