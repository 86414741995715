import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getApi, postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Addairport() {
  const navigate = useNavigate();
  const [airport, setAirport] = useState({
    name: "",
    icaoCode: "",
    iataCode: "",
    Country: "",
    City: "",
    HandlingFees: "",
    FuelSurcharges: "",
    substituteBaseFor: "",
  });
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAirport((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const airportObj = {
      name: airport.name,
      icao_code: airport.icaoCode,
      iata_code: airport.iataCode,
      country: airport.Country,
      airportCity: airport.City,
      handlingFees: airport.HandlingFees,
      fuelSurcharge: airport.FuelSurcharges,
      substituteBaseFor: airport.substituteBaseFor=""?airport.substituteBaseFor:null,
    };

    try {
      const response = await postApi("insertAirport", airportObj);
      console.log("response", response.data);
      if (response?.status) {
        const data = await response.data;
        setAirport({
          name: "",
          icaoCode: "",
          iataCode: "",
          Country: "",
          City: "",
          HandlingFees: "",
          FuelSurcharges: "",
          substituteBaseFor: "",
        });
        console.log(data);
        toast.success("Airport added Successful", {
          onClose: () => navigate(-1), // Navigate after toast is closed
          autoClose: 1000, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  useEffect(() => {
    getApi("getBaseslist")
      .then((res) => {
        console.log(res.data);
        const fetchedOptions = res.data.map((item) => ({
          value: item._id, // or the appropriate key
          label: item.airportName, // or the appropriate key
        }));
        setOptions(fetchedOptions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, []);
  const handleSelectChange = (selectedOption) => {
    handleChange({
      target: {
        name: 'substituteBaseFor',
        value: selectedOption ? selectedOption.value : '',
      },
    });
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Add Airport</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Airports"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        value={airport.name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>ICAO Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="icaoCode"
                        placeholder="Enter Code"
                        value={airport.icaoCode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>IATA Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="iataCode"
                        placeholder="Enter Code"
                        value={airport.iataCode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="Country"
                        placeholder="Enter Country"
                        value={airport.Country}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="City"
                        placeholder="Enter City"
                        value={airport.City}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Substitute Base</Form.Label>
                      <Select
                        name="substituteBase"
                        placeholder="Enter Base Airport"
                        value={options.find(
                          (option) => option.value === airport.substituteBaseFor
                        )}
                        onChange={handleSelectChange}
                        options={options}
                        isClearable
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h4 className="formmain_head">Airport Fees</h4>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Handling Fees</Form.Label>
                      <Form.Control
                        type="number"
                        name="HandlingFees"
                        placeholder="Enter Handling Fees"
                        value={airport.HandlingFees}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Fuel Surcharges</Form.Label>
                      <Form.Control
                        type="number"
                        name="FuelSurcharges"
                        placeholder="Enter Fuel Surcharges"
                        value={airport.FuelSurcharges}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  className="btn btn-primary px-4 mt-3 min_width140"
                  type="Submit" 
                  disabled={submitting}
                >
                  Save
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
