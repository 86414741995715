import React, { useState , forwardRef } from "react";
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export default function Editflight() {
    const [Name, setName] = useState('Global 6000');
    const [Airplane, setAirplane] = useState('1');
    const [From, setFrom] = useState('Temale International Airport');
    const [To, setTo] = useState('Kumasi International Airport');
    const [Departuretime, setDeparturetime] = useState('09:00');
    const [ArrivalTime, setArrivalTime] = useState('17:15');
    const [Price, setPrice] = useState('$ 850');
    const [selectedDates, setSelectedDates] = useState([]);
    const handleDateSelect = (date) => {
        // Check if the date is already in the selectedDates array
        const index = selectedDates.findIndex((selectedDate) =>
          selectedDate.toDateString() === date.toDateString()
        );
    
        // If the date is not in the array, add it; otherwise, remove it
        if (index === -1) {
          setSelectedDates([...selectedDates, date]);
        } else {
          const newSelectedDates = [...selectedDates];
          newSelectedDates.splice(index, 1);
          setSelectedDates(newSelectedDates);
        }
      };
      const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
        <Form.Control
            className="example-custom-input"
            onClick={onClick}
            ref={ref}
            value={selectedDates.map((date) => {
                const month = date.toLocaleString('default', { month: 'short' });
                const day = date.getDate();
                return `${month} ${day}`;
            }).join(', ')}
        />
    ));

    return (
        <React.Fragment>
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
                    <h5>Edit Flight</h5>
                    <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140" to="/Flights">Back</Link>
                </Card.Header>
                <Card.Body>
                    <Row className="mt-3">
                        <Col md={12}>
                            <Form>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Name" value={Name} onChange={(e) => setName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Airplane</Form.Label>
                                            <Form.Select aria-label="Select Airplane" value={Airplane} onChange={(e) => setAirplane(e.target.value)} >
                                                <option value="select">Select Airplane</option>
                                                <option value="1">Ultra Long Range Jet</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>From</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Location" value={From} onChange={(e) => setFrom(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>To</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Location" value={To} onChange={(e) => setTo(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Departure Time</Form.Label>
                                            <Form.Control type="time" placeholder="Enter Departure Time" value={Departuretime} onChange={(e) => setDeparturetime(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Arrival Time</Form.Label>
                                            <Form.Control type="time" placeholder="Enter Arrival Time" value={ArrivalTime} onChange={(e) => setArrivalTime(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Price" value={Price} onChange={(e) => setPrice(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Date</Form.Label>
                                            <DatePicker
                                                onChange={handleDateSelect}
                                                dateFormat="YYYY"
                                                highlightDates={selectedDates}
                                                customInput={<ExampleCustomInput />}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button className="btn btn-primary px-4 mt-3 min_width140">Update</Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}