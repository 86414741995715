import React, { useEffect,useState } from "react";
import axios from "axios";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { getApi,postApi } from "./axiosInstance/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddTaxes() {
  const [FederalTax, setFederalTax] = useState("");
  const [SegmentTax, setSegmentTax] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const tax = {
      federalTax: parseFloat(FederalTax),
      SegmentTax: parseFloat(SegmentTax),
    };
  
    try {
      const response = await postApi("insertTax", tax);
      if (response?.status) {
        const data = await response.data;
        console.log(data);
        toast.success("Edit Successful", {
          autoClose: 100, // Display toast for 3 seconds
        });
      } else {
        setSubmitting(false);
        toast.error(response);
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setError("There was an error submitting the form!");
    }
  };
  useEffect(() => {
    getApi("getTax")
      .then((res) => {
        console.log(res.data);
        setFederalTax(res.data.federalTax);
        setSegmentTax(res.data.SegmentTax);
      })
      .catch((err) => {
        console.log(err, "errorr here");
        setError(err.message);
        setLoading(false);
      });
  }, []);
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-4">
          <h5>Add Taxes</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Federal Excise Tax</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Federal Excise Tax"
                    value={FederalTax}
                    onChange={(e) => setFederalTax(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Segment Tax</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Segment Tax"
                    value={SegmentTax}
                    onChange={(e) => setSegmentTax(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button className="btn btn-primary px-4 mt-3 min_width140" type="submit">
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}
